/**
 * @generated SignedSource<<67d48593cb7f182adcac5323cdef7523>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type unified_ingest_transform_templates_write_params = {
  example_file_id?: string | null;
  file_type?: string | null;
  is_production: boolean;
  org_slug: string;
  owner_name: string;
  source_system?: string | null;
  steps: any;
  target_table?: string | null;
  template_display_name: string;
  template_id: string;
  user_id: string;
};
export type useUnifiedIngestWriteTemplatesMutation$variables = {
  params: unified_ingest_transform_templates_write_params;
};
export type useUnifiedIngestWriteTemplatesMutation$data = {
  readonly unified_ingest_transform_templates_write: {
    readonly owner_name: string | null;
    readonly user_id: string | null;
  };
};
export type useUnifiedIngestWriteTemplatesMutation = {
  response: useUnifiedIngestWriteTemplatesMutation$data;
  variables: useUnifiedIngestWriteTemplatesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_transform_templates_write_output",
    "kind": "LinkedField",
    "name": "unified_ingest_transform_templates_write",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestWriteTemplatesMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestWriteTemplatesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "12829b60d407af10f162fc711c46d4c9",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestWriteTemplatesMutation",
    "operationKind": "mutation",
    "text": "mutation useUnifiedIngestWriteTemplatesMutation(\n  $params: unified_ingest_transform_templates_write_params!\n) {\n  unified_ingest_transform_templates_write(params: $params) {\n    owner_name\n    user_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf64869965063ee39660686901ec4934";

export default node;
