/**
 * @generated SignedSource<<0a5d09c64f277a7125df00e1d5098ee8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_transform_templates_read_params = {
  owner_name: string;
  template_id?: string | null;
  template_uuid: string;
};
export type useUnifiedIngestReadTemplatesQuery$variables = {
  params: unified_ingest_transform_templates_read_params;
};
export type useUnifiedIngestReadTemplatesQuery$data = {
  readonly unified_ingest_transform_templates_read: {
    readonly owner_name: string;
    readonly template_data: any | null;
  } | null;
};
export type useUnifiedIngestReadTemplatesQuery = {
  response: useUnifiedIngestReadTemplatesQuery$data;
  variables: useUnifiedIngestReadTemplatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_transform_templates_read_output",
    "kind": "LinkedField",
    "name": "unified_ingest_transform_templates_read",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template_data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestReadTemplatesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestReadTemplatesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87a36636ecd491a4b2604685dfedbf60",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestReadTemplatesQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestReadTemplatesQuery(\n  $params: unified_ingest_transform_templates_read_params!\n) {\n  unified_ingest_transform_templates_read(params: $params) {\n    owner_name\n    template_data\n  }\n}\n"
  }
};
})();

(node as any).hash = "357cd912d5f2ca15178b396e4bad9388";

export default node;
