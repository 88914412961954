import { MultiAppsIcon } from '@cherre-frontend/ui';
import React, { FC } from 'react';
import { RouteListType } from 'src/utils/routing';
import { createRoute } from 'src/utils/routing/routeFactory';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { RelayEnvironmentScope } from '@cherre-frontend/data-fetching';

const LIBRARIES_GROUP = 'Libraries';

const RelayWrapper = <P extends JSX.IntrinsicAttributes>(
  Component: React.LazyExoticComponent<FC<object>>
): FC<P> => {
  const WrappedComponent: FC<P> = (props: P) => (
    <RelayEnvironmentScope environment='dom_admin'>
      <Component {...props} />
    </RelayEnvironmentScope>
  );

  WrappedComponent.displayName = `RelayWrapper(${
    Component._result?.displayName ?? 'Component'
  })`;
  return WrappedComponent;
};

export const ModelsLibraryRoute = createRoute({
  path: '/libraries/models',
  component: RelayWrapper(React.lazy(() => import('./pages/models-library'))),
  menu: {
    title: 'Data Models Library',
    icon: MultiAppsIcon,
    group: LIBRARIES_GROUP,
  },
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});
export const ModelDetailsRoute = createRoute({
  path: '/libraries/models/:modelId',
  component: RelayWrapper(React.lazy(() => import('./pages/model-details'))),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});
export const ModelCreateRoute = createRoute({
  path: '/libraries/models/create',
  component: RelayWrapper(React.lazy(() => import('./pages/model-create'))),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});
export const ModelTableDetailsRoute = createRoute({
  path: '/libraries/models/:modelId/target-table/:tableId',
  component: RelayWrapper(
    React.lazy(() => import('./pages/target-table-details'))
  ),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});

export const ModelTableCreateRoute = createRoute({
  path: '/libraries/models/:modelId/target-table/create',
  component: RelayWrapper(
    React.lazy(() => import('./pages/target-table-create'))
  ),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});
export const TableExtractionTemplateCreateRoute = createRoute({
  path: '/libraries/models/:modelId/target-table/:tableId/extraction-template/create',
  component: RelayWrapper(
    React.lazy(() => import('./pages/extraction-template-create'))
  ),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});
export const TableExtractionTemplateUploadRoute = createRoute({
  path: '/libraries/models/:modelId/target-table/:tableId/extraction-template/upload',
  component: RelayWrapper(
    React.lazy(() => import('./pages/extraction-template-create/updload'))
  ),
  permissionHook: () => {
    return useFeatureFlag('ModelLibraryEnabled') ?? false;
  },
});

export const librariesRoutes: RouteListType = [
  {
    permissions: ['CherreAdmin', 'DSPAdmin'],
    header: LIBRARIES_GROUP,
    routes: [
      ModelsLibraryRoute.routeDescriptor,
      ModelCreateRoute.routeDescriptor,
      ModelDetailsRoute.routeDescriptor,
      ModelTableCreateRoute.routeDescriptor,
      ModelTableDetailsRoute.routeDescriptor,
      TableExtractionTemplateCreateRoute.routeDescriptor,
      TableExtractionTemplateUploadRoute.routeDescriptor,
    ],
  },
] satisfies RouteListType;
