import { useMemo } from 'react';

export const useOrganitionsOptions = () => {
  //TODO: @gaspardetienne97 implement logic to fetch organizations from the DOM
  const loading = false;
  const autoCompleteOptions = ['Cherre', 'OTPP', 'Nuveen', 'MRI', 'Yardi'];

  const options = useMemo(
    () =>
      autoCompleteOptions?.map((org) => ({
        label: org,
        value: org.toLowerCase(),
      })),
    [autoCompleteOptions]
  );
  return { options, loading };
};
