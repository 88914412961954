import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  useUnifiedIngestListTemplatesQuery,
  unified_ingest_transform_templates_list_params,
} from './__generated__/useUnifiedIngestListTemplatesQuery.graphql';

export const $unifiedIngestListTransformTemplates = graphQLSelector({
  query: graphql`
    query useUnifiedIngestListTemplatesQuery(
      $params: unified_ingest_transform_templates_list_params!
    ) {
      unified_ingest_transform_templates_list(params: $params) {
        owner_name
        templates_list {
          user_id
          org_slug
          template_id
          template_display_name
          template_uuid
          source_system
          target_table
          is_production
        }
      }
    }
  ` as GraphQLReturn<useUnifiedIngestListTemplatesQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_transform_templates_list;
  },
});

export const useUnifiedIngestListTransformTemplates = (
  params: unified_ingest_transform_templates_list_params
) => {
  return useCherreValue(
    $unifiedIngestListTransformTemplates({
      params,
    })
  );
};
