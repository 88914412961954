import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useModelTableDetailsQuery } from './__generated__/useModelTableDetailsQuery.graphql';

const $modelTableDetails = graphQLSelector({
  environment: 'dom',
  query: graphql`
    query useModelTableDetailsQuery(
      $modelId: String
      $targetTableDefinitionId: String
    ) {
      targetTableDefinitionsPackage(uid: $modelId) {
        uid
        name
        displayName
      }
      targetTableDefinition(uid: $targetTableDefinitionId) {
        uid
        name
        displayName
        description
        owner {
          name
        }
        dsp {
          submissionType
          orgSlug
        }
        columns {
          name
          displayName
          type
          description
          dsp {
            required
            mappingFields {
              mappingName
              targetName
              displayName
              required
              tsa {
                required
                enabled
              }
            }
            tsa {
              required
              enabled
            }
          }
        }
      }
    }
  ` as GraphQLReturn<useModelTableDetailsQuery>,
});

export const useModelTableDetails = (
  modelId?: string,
  targetTableDefinitionId?: string
) => {
  const modelDetails =
    modelId && targetTableDefinitionId
      ? useCherreValue($modelTableDetails({ modelId, targetTableDefinitionId }))
      : null;

  return modelDetails;
};
