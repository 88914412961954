/**
 * @generated SignedSource<<7527eba13f0c64e8c609d9d2e74d4e64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_transform_templates_list_params = {
  org_slug?: string | null;
  owner_name: string;
  source_system?: string | null;
  target_table?: string | null;
  template_id?: string | null;
  template_uuid?: string | null;
  user_id?: string | null;
};
export type useUnifiedIngestListTemplatesQuery$variables = {
  params: unified_ingest_transform_templates_list_params;
};
export type useUnifiedIngestListTemplatesQuery$data = {
  readonly unified_ingest_transform_templates_list: {
    readonly owner_name: string;
    readonly templates_list: ReadonlyArray<{
      readonly is_production: boolean | null;
      readonly org_slug: string | null;
      readonly source_system: string | null;
      readonly target_table: string | null;
      readonly template_display_name: string | null;
      readonly template_id: string | null;
      readonly template_uuid: string | null;
      readonly user_id: string | null;
    } | null> | null;
  } | null;
};
export type useUnifiedIngestListTemplatesQuery = {
  response: useUnifiedIngestListTemplatesQuery$data;
  variables: useUnifiedIngestListTemplatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_transform_templates_list_output",
    "kind": "LinkedField",
    "name": "unified_ingest_transform_templates_list",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "unified_ingest_transform_templates_list_output_template",
        "kind": "LinkedField",
        "name": "templates_list",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "org_slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "template_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "template_display_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "template_uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source_system",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "target_table",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_production",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestListTemplatesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestListTemplatesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8d1d182352418a93b6e63442399259db",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestListTemplatesQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestListTemplatesQuery(\n  $params: unified_ingest_transform_templates_list_params!\n) {\n  unified_ingest_transform_templates_list(params: $params) {\n    owner_name\n    templates_list {\n      user_id\n      org_slug\n      template_id\n      template_display_name\n      template_uuid\n      source_system\n      target_table\n      is_production\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab73300feaf2aac2296b73da07a497b8";

export default node;
