import React from 'react';
import {
  Box,
  Paper,
  Typography,
  MuiAutocomplete as Autocomplete,
  TextField,
} from '@cherre-frontend/ui';

export interface OrganizationOption {
  label: string;
  value: string;
}
type ModelLibrarySectionProps<T> = {
  title: string;
  description: string;
  autoCompleteOptions: T[];
  selectedValues: T[];
  onAutoCompleteChange?: (value: T[]) => void;
  actionButton?: React.ReactNode;
};
export const ModelLibrarySection = <T extends { label: string }>({
  title,
  description,
  autoCompleteOptions,
  onAutoCompleteChange,
  selectedValues,
  actionButton,
}: ModelLibrarySectionProps<T>) => {
  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box display='flex' flexDirection='column' gap='8px'>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          gap='4px'
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            gap='4px'
          >
            <Typography variant='h6'>{title}</Typography>
            <Typography variant='body1'>{description}</Typography>
          </Box>
          {actionButton}
        </Box>

        <Autocomplete
          value={selectedValues}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              color='primary'
              placeholder='Select'
              size='small'
            />
          )}
          onChange={(_, value) => {
            onAutoCompleteChange?.(value);
          }}
          options={autoCompleteOptions}
        />
      </Box>
    </Paper>
  );
};

ModelLibrarySection.displayName = 'ModelLibrarySection';
export default ModelLibrarySection;
