import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestWriteTemplatesMutation } from './__generated__/useUnifiedIngestWriteTemplatesMutation.graphql';

export const useUnifiedIngestWriteTemplates = () => {
  return useMutationAsync(
    graphql`
      mutation useUnifiedIngestWriteTemplatesMutation(
        $params: unified_ingest_transform_templates_write_params!
      ) {
        unified_ingest_transform_templates_write(params: $params) {
          owner_name
          user_id
        }
      }
    ` as GraphQLReturn<useUnifiedIngestWriteTemplatesMutation>
  );
};
