import React, { useMemo } from 'react';
import { Button, AddCircleIcon } from '@cherre-frontend/ui';
import {
  RelayEnvironmentScope,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import ModelLibrarySection from './ModelLibrarySection';
import { $unifiedIngestReadTransformTemplates } from '../services/dsp/useUnifiedIngestReadTemplates';
import { $unifiedIngestListTransformTemplates } from '../services/dsp/useUnifiedIngestListTemplates';
import { useSelector } from 'react-redux';
import { useModelTableDetails } from '../services/dom/useModelTableDetails';
import { useParams } from 'react-router';
export interface OrganizationOption {
  label: string;
  value: string;
}
type ModelLibrarySectionProps = {
  targetTableName?: string;
  handleCreateClick?: () => void;
};
export const TemplatesSection: React.FC<ModelLibrarySectionProps> = ({
  targetTableName,
  handleCreateClick,
}) => {
  const { domOwner, organizationSlug } = useSelector(
    (state) => state.user.profile.value
  );
  const readTemplates = $unifiedIngestReadTransformTemplates;
  const getTemplateList = useCherreValue(
    $unifiedIngestListTransformTemplates({
      params: {
        owner_name: domOwner ?? '',
      },
    })
  );
  const getTableTemplateList = useCherreValue(
    $unifiedIngestListTransformTemplates({
      params: {
        owner_name: domOwner ?? '',
        org_slug: organizationSlug ?? '',
        target_table: targetTableName,
      },
    })
  );
  const autoCompleteOptions = useMemo(
    () =>
      getTemplateList?.templates_list?.map((template) => ({
        label: template?.template_display_name ?? '',
        value: template?.template_uuid ?? '',
      })) ?? [],
    [getTemplateList]
  );
  const selectedOptions = useMemo(
    () =>
      getTableTemplateList?.templates_list?.map((template) => ({
        label: template?.template_display_name ?? '',
        value: template?.template_uuid ?? '',
      })) ?? [],
    [getTableTemplateList]
  );
  const handleExtractionTemplateAutoCompleteChange = (
    option: { label: string; value: string }[]
  ) => {
    option.map((selectedOption) => {
      const template = useCherreValue(
        readTemplates({
          params: {
            owner_name: domOwner ?? '',
            template_uuid: selectedOption.value,
          },
        })
      );
      return template?.template_data;
    });
  };
  return (
    <ModelLibrarySection
      title='Extraction Templates'
      description='Search and select which extraction templates are assigned to this target table.'
      autoCompleteOptions={autoCompleteOptions}
      selectedValues={selectedOptions}
      onAutoCompleteChange={handleExtractionTemplateAutoCompleteChange}
      actionButton={
        <Button endIcon={<AddCircleIcon />} onClick={handleCreateClick}>
          Create
        </Button>
      }
    />
  );
};

interface TemplatesSectionWrapperProps {
  handleCreateClick?: () => void;
}
const TemplatesSectionWrapper: React.FC<TemplatesSectionWrapperProps> = ({
  handleCreateClick,
}) => {
  const { modelId, tableId } = useParams<{
    modelId: string;
    tableId: string;
  }>();
  const { targetTableDefinition } =
    useModelTableDetails(modelId, tableId) ?? {};
  return (
    <RelayEnvironmentScope environment='view_admin'>
      <TemplatesSection
        targetTableName={targetTableDefinition?.name}
        handleCreateClick={handleCreateClick}
      />
    </RelayEnvironmentScope>
  );
};

TemplatesSectionWrapper.displayName = 'TemplatesSectionWrapper';
export default TemplatesSectionWrapper;
