import {
  PageContainer,
  Panel,
  Typography,
  Box,
  Paper,
  Button,
  CloudUploadIcon,
  ControlledInput,
  ControlledCheckbox,
  Tabs,
  DataConnectionIcon,
  DataSettingsIcon,
  SettingsIcon,
  Divider,
  MuiAutocomplete as Autocomplete,
  TextField,
  Grid2 as Grid,
} from '@cherre-frontend/ui';
import React, { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ModelDetailsRoute } from '../../routes';
import { useModelTableCreate } from '../../services/dom/useModelTableCreate';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import SchemaTable from '../../components/SchemaTable';
import DspMappingTable from '../../components/DspMappingTable';
import DspConfigurationTable from '../../components/DspConfigurationTable';
import { useLibraryModelDetails } from '../../services/dom/useLibraryModelDetails';
import { useLibraryModelUpdate } from '../../services/dom/useLibraryModelUpdate';
import { useAppContext } from '@cherre-frontend/core';
import { useTargetTableForms } from '../../hooks/useTargetTableForms';
import { TabValue, TargetTableFormValues, TSA_VALUES } from '../../schema';
import { Controller } from 'react-hook-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { toLowerCaseKabobCaseAlphaNumeric } from '../../utils';

const TargetTableCreate: React.FC = () => {
  const context = useAppContext();
  const history = useHistory();
  const { modelId } = useParams<{ modelId: string }>();
  const [createdTable, setCreatedTable] = React.useState<boolean>(false);
  const modelDetails = useLibraryModelDetails(modelId);
  const updateModel = useLibraryModelUpdate();
  const [tabValue, setTabValue] = useState(TabValue.TableSchema);

  const {
    tableForm,
    mappingForm,
    tsaForm,
    values,
    setTableData,
    setMappingTableData,
    setTsaTableData,
  } = useTargetTableForms();

  const orgSlug = useSelector(
    (state) => state.user.profile.value.organizationSlug
  );

  const { dsp, tableData, fieldNameOptions, mappingFieldsData, tsaFieldsData } =
    values;

  const createTable = useModelTableCreate();

  const updateModelTables = (tableId) => {
    //TODO: @gaspardetienne97 use uid instead of name
    const targetTableDefinitionNames =
      modelDetails?.targetTableDefinitions?.map((table) => table.name) ?? [];
    updateModel({
      targetTableDefinitionNames: [...targetTableDefinitionNames, tableId],
      targetTableDefinitionsPackageUID: modelDetails?.uid ?? '',
      displayName: modelDetails?.displayName ?? '',
    });
  };

  const handleFileUploadClick = (_event: React.MouseEvent) => {
    //TODO: @gaspardetienne97 implement handleFileUploadClick
    throw new Error('Function not implemented.');
  };

  const handleTsaNotRequiredFieldsChange = (event, value) => {
    console.log(value);
    throw new Error('Function not implemented.');
  };

  const onSubmit = async (data: TargetTableFormValues) => {
    console.log('Form submitted with data:', data);
    try {
      const tableData = {
        targetTableDefinitionName: toLowerCaseKabobCaseAlphaNumeric(
          data.tableName
        ),
        displayName: data.tableName,
        description: data.description,
        dsp: data.dsp
          ? {
              submissionType: data.submissionType,
              orgSlug: orgSlug,
            }
          : null,
        columns: data.tableData.map((column) => {
          const mappingFields = mappingFieldsData?.find(
            (field) => field.fieldName === column.name
          );
          const tsaFields = tsaFieldsData?.find(
            (field) => field.fieldName === column.name
          );
          const tsa = {
            required: tsaFields?.tsa === TSA_VALUES.REQUIRED || false,
            enabled: tsaFields?.tsa !== TSA_VALUES.DISABLED || false,
          };
          return {
            ...column,
            dsp: data.dsp
              ? {
                  ...column.dsp,
                  required: values.requiredFields
                    ? values.requiredFields.includes(column.name)
                    : false,
                  mappingFields: mappingFields
                    ? [
                        {
                          mappingName: mappingFields.fieldName ?? '',
                          targetName: mappingFields.referenceColumn ?? '',
                          displayName: mappingFields.lookupTable ?? '',
                          //TODO: @gaspardetienne97 implement required field for mappingFields
                          required: !!mappingFields.lookupColumn,
                          tsa,
                        },
                      ]
                    : null,
                  tsa,
                }
              : null,
          };
        }),
      };
      console.log('Table data:', tableData);
      const response = await createTable(tableData);
      await updateModelTables(response.createTargetTableDefinition.name);
      setCreatedTable(true);
      context.showSnackbar({
        message: 'Target table created successfully',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      context.showSnackbar({
        message: 'Failed to create target table',
        type: 'error',
      });
    }
  };

  return (
    <PageContainer id='TargetTableCreatePage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='TargetTableCreateBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs modelName={modelDetails?.displayName} />
        </Panel>
        <Panel id='TargetTableCreateHeader' behavior='fallback'>
          <Typography variant='h6'>Custom Data Model</Typography>{' '}
        </Panel>
        <Panel id='TargetTableCreateDetails' behavior='fallback'>
          <Paper
            sx={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography variant='h6'>Define your model</Typography>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='10px'
                  height='100%'
                >
                  <Typography variant='h6'>1. Target Table Name</Typography>
                  <Typography variant='body1'>
                    {`You're creating a target table that's not part of our
                      standard library. To start, what would you like to name this
                      table?`}
                  </Typography>
                  <Box flexGrow={1} />
                  <ControlledInput
                    size='small'
                    fullWidth
                    placeholder='Enter target table name'
                    control={tableForm.control}
                    name='tableName'
                    error={!!tableForm.errors.tableName}
                    helperText={tableForm.errors.tableName?.message}
                  />
                </Box>
              </Grid>
              <Grid xs={12} md={6}>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='10px'
                  height='100%'
                >
                  <Typography variant='h6'>
                    2. Target Table Description (Optional)
                  </Typography>
                  <Typography variant='body1'>
                    Add a short description about this target table.
                  </Typography>
                  <Box flexGrow={1} />
                  <ControlledInput
                    control={tableForm.control}
                    name='description'
                    placeholder='Enter target table description'
                    size='small'
                    fullWidth
                    error={!!tableForm.errors.description}
                  />
                </Box>
              </Grid>
              <Grid xs={12} md={6}>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='10px'
                  height='100%'
                >
                  <Typography variant='h6'>
                    3. Data Submission Portal
                  </Typography>
                  <Typography variant='body1'>
                    Is this a target table for the data submission portal?
                    Checking this box will allow you to configure columns
                    specifically for the data submission portal.
                  </Typography>
                  <Box flexGrow={1} />
                  <ControlledCheckbox
                    control={tableForm.control}
                    name='dsp'
                    label='For use within the Data Submission Portal'
                  />
                </Box>
              </Grid>
              {dsp ? (
                <Grid xs={12} md={6}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='10px'
                    height='100%'
                  >
                    <Typography variant='h6'>
                      4. Data Submission Portal - Submission Type
                    </Typography>
                    <Typography variant='body1'>
                      What type of target table does this represent with the
                      data submission portal? Ex. Financial or Operational
                    </Typography>
                    <Box flexGrow={1} />
                    <Controller
                      name='submissionType'
                      control={tableForm.control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          fullWidth
                          options={['financial', 'operational']}
                          getOptionLabel={(option) => capitalize(option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              color='primary'
                              size='small'
                              placeholder='Select submission type'
                              error={!!tableForm.errors['submissionType']}
                              helperText={
                                tableForm.errors['submissionType']?.message
                              }
                            />
                          )}
                          onChange={(_, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Panel>

        <Panel id='TargetTableCreateFields' behavior='fallback'>
          <Box display='flex' flexDirection='column' gap='20px'>
            <Paper
              sx={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
              >
                <Box display='flex' flexDirection='column' gap='10px'>
                  <Typography variant='h6'>{`${
                    dsp ? 5 : 4
                  }. Add target fields`}</Typography>
                  <Typography variant='body1'>
                    {`Add target fields below and define each field's data type or
                    upload a CSV with the columns below to get started.`}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent={'center'}
                >
                  <Button
                    size='small'
                    variant='contained'
                    endIcon={<CloudUploadIcon />}
                    onClick={handleFileUploadClick}
                  >
                    Upload File
                  </Button>
                </Box>
              </Box>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                aria-label='Target Table tabs '
                showBorder={false}
                muiTabsProps={{
                  TabIndicatorProps: {
                    sx: {
                      top: 0,
                    },
                  },
                }}
                tabs={[
                  {
                    label: 'Table Schema',
                    icon: <DataConnectionIcon />,
                    iconPosition: 'start',
                    value: TabValue.TableSchema,
                  },
                  ...(dsp
                    ? [
                        {
                          label: 'Mapping Configurations',
                          icon: <DataSettingsIcon />,
                          iconPosition: 'start' as const,
                          value: TabValue.MappingConfig,
                        },
                        {
                          label: 'Product Configurations',
                          icon: <SettingsIcon />,
                          iconPosition: 'start' as const,
                          value: TabValue.ProductConfig,
                        },
                      ]
                    : []),
                ]}
                tabPanels={[
                  {
                    value: TabValue.TableSchema,
                    panel: (
                      <SchemaTable
                        control={tableForm.control}
                        tableCreated={false}
                        targetTableState={tableData}
                        setTargetTableState={setTableData}
                      />
                    ),
                  },
                  //TODO: @gaspardetienne97 implement the Mapping Configurations tab to actually display the mapping configurations this is just a placeholder to show the ui

                  ...(dsp
                    ? [
                        {
                          value: TabValue.MappingConfig,
                          panel: (
                            <Box
                              display='flex'
                              flexDirection='column'
                              gap='20px'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                gap='8px'
                              >
                                <Typography variant='h6'>
                                  Configure mapped fields below
                                </Typography>
                                <Typography variant='body1'>
                                  From the table schema created, are there any
                                  fields that map to another? If so, add those
                                  fields below.
                                </Typography>
                              </Box>
                              <DspMappingTable
                                control={mappingForm.control}
                                tableCreated={false}
                                fieldNameOptions={fieldNameOptions}
                                targetTableState={mappingFieldsData}
                                setTargetTableState={setMappingTableData}
                              />
                            </Box>
                          ),
                        },
                        //TODO: @gaspardetienne97 implement the Product Configurations tab to actually display the product configurations this is just a placeholder to show the ui
                        {
                          value: TabValue.ProductConfig,
                          panel: (
                            <Box
                              display='flex'
                              flexDirection='column'
                              gap='20px'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                gap='8px'
                              >
                                <Typography variant='h6'>
                                  Data Submission Portal - Not Required Fields
                                </Typography>
                                <Typography variant='body1'>
                                  From the table schema created, are there any
                                  fields that are not required for data
                                  submissions?
                                </Typography>
                                <Autocomplete
                                  fullWidth
                                  onChange={handleTsaNotRequiredFieldsChange}
                                  options={fieldNameOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      color='primary'
                                      placeholder='Select'
                                      size='small'
                                    />
                                  )}
                                />
                              </Box>
                              <Divider />
                              <Typography variant='h6'>
                                Data Submission Portal - Topside Adjustments
                              </Typography>
                              <DspConfigurationTable
                                control={tsaForm.control}
                                tableCreated={false}
                                targetTableState={tsaFieldsData}
                                setTargetTableState={setTsaTableData}
                                fieldNameOptions={fieldNameOptions}
                              />
                            </Box>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            </Paper>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='end'
              alignItems='center'
              gap='20px'
            >
              <Button
                onClick={() =>
                  history.push(
                    generatePath(ModelDetailsRoute.routeDescriptor.path, {
                      modelId,
                    })
                  )
                }
                type='button'
              >
                Back
              </Button>
              <Button
                disabled={createdTable}
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  tableForm.handleSubmit(onSubmit)();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Panel>
      </Box>
    </PageContainer>
  );
};

TargetTableCreate.displayName = 'TargetTableCreate';
export default TargetTableCreate;
