import {
  PageContainer,
  Panel,
  Typography,
  Box,
  Paper,
  AddCircleIcon,
  Button,
  ControlledInput,
} from '@cherre-frontend/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useAppContext } from '@cherre-frontend/core';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import { useLibraryModelCreate } from '../../services/dom/useLibraryModelCreate';
import { generatePath, useHistory } from 'react-router-dom';
import { ModelsLibraryRoute, ModelTableCreateRoute } from '../../routes';
import { toLowerCaseKabobCaseAlphaNumeric } from '../../utils';

const schema = z.object({
  targetTableDefinitionsPackageName: z.string().optional(),
  displayName: z.string().min(1, 'Model name is required'),
  description: z.string().optional(),
  organization: z.string().optional(),
});

type FormValues = z.infer<typeof schema>;

const ModelCreate: React.FC = () => {
  const history = useHistory();
  const context = useAppContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      targetTableDefinitionsPackageName: '',
      displayName: '',
      description: '',
      organization: '',
    },
  });
  const [createdModelId, setCreatedModelId] = React.useState<string>('');
  const createModel = useLibraryModelCreate();

  const onSubmit = async (data: FormValues) => {
    try {
      const createdModel = await createModel({
        targetTableDefinitionsPackageName: toLowerCaseKabobCaseAlphaNumeric(
          data.displayName
        ),
        targetTableDefinitionNames: [],
        displayName: data.displayName,
        description: data.description,
        //orgSlug: data.organization?.value,
      });
      setCreatedModelId(createdModel.createTargetTableDefinitionsPackage.uid);
      context.showSnackbar({
        message: 'Model created successfully',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      context.showSnackbar({ message: 'Error creating Model', type: 'error' });
    }
  };

  return (
    <PageContainer id='ModelCreatePage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='ModelCreateBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs />
        </Panel>
        <Panel id='ModelCreateHeader' behavior='fallback'>
          <Typography variant='h6'>Custom Data Model</Typography>{' '}
        </Panel>
        <Panel id='ModelCreateOrganization' behavior='fallback'>
          <Paper
            sx={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography variant='h6'>Define your model</Typography>
            <Box display='flex' flexDirection='row' gap='20px'>
              <Box display='flex' flexDirection='column' gap='10px'>
                <Typography variant='h6'>1. Model Name</Typography>
                <Typography variant='body1'>
                  {`You're creating a model that's not part of our standard
                  library. To start, what would you like to name this model?`}
                </Typography>
                <ControlledInput
                  size='small'
                  control={control}
                  name='displayName'
                  error={!!errors.displayName}
                  helperText={errors.displayName?.message}
                />
              </Box>
              <Box display='flex' flexDirection='column' gap='10px'>
                <Typography variant='h6'>2. Organization</Typography>
                <Typography variant='body1'>
                  Select which organization this model should be assigned to in
                  order for them to view the below model in their library.{' '}
                </Typography>
                <ControlledInput
                  size='small'
                  control={control}
                  name='organization'
                  error={!!errors.organization}
                  helperText={errors.organization?.message}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' gap='10px'>
              <Typography variant='h6'>
                3. Model Description (Optional)
              </Typography>
              <Typography variant='body1'>
                Add a short description about this model.
              </Typography>
              <ControlledInput
                control={control}
                name='description'
                size='small'
                error={!!errors.description}
              />
            </Box>
          </Paper>
        </Panel>
        <Panel
          id='ModelCreateTables'
          behavior='fallback'
          styles={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {createdModelId && (
            <Paper sx={{ padding: '20px' }}>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                gap='10px'
              >
                <Typography variant='h6'>4. Add target tables</Typography>
                <Button
                  size='small'
                  variant='contained'
                  endIcon={<AddCircleIcon />}
                  onClick={() =>
                    history.push(
                      generatePath(ModelTableCreateRoute.routeDescriptor.path, {
                        modelId: createdModelId,
                      })
                    )
                  }
                >
                  Add Target Table
                </Button>
              </Box>
            </Paper>
          )}
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='end'
            alignItems='center'
            gap='20px'
          >
            <Button
              onClick={() =>
                history.push(
                  generatePath(ModelsLibraryRoute.routeDescriptor.path)
                )
              }
            >
              Back
            </Button>
            <Button
              disabled={Boolean(createdModelId)}
              variant='contained'
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Box>
        </Panel>
      </Box>
    </PageContainer>
  );
};

ModelCreate.displayName = 'ModelCreate';
export default ModelCreate;
