import {
  PageContainer,
  Panel,
  Box,
  TextField,
  Button,
  Paper,
  Grid2 as Grid,
} from '@cherre-frontend/ui';
import React, { useState } from 'react';
import { useCherreState, atom } from '@cherre-frontend/data-fetching';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import Header from '../../components/ModelsLibraryHeader';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import {
  ModelTableDetailsRoute,
  TableExtractionTemplateUploadRoute,
} from '../../routes';
import { useModelTableDetails } from '../../services/dom/useModelTableDetails';
import TemplatesSectionWrapper from '../../components/TemplatesSection';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const extractionTemplateSchema = z.object({
  templateName: z.string().min(1, 'Template name is required'),
  erpSystem: z.string().optional(),
  fileType: z.string().optional(),
});

// Infer TypeScript type from the zod schema
type ExtractionTemplateFormData = z.infer<typeof extractionTemplateSchema>;

export const extractionTemplateState = atom<ExtractionTemplateFormData>({
  key: 'table-extraction-template-state',
  default: {
    templateName: '',
    erpSystem: '',
    fileType: '',
  },
});

const ExtractionTemplateDetails: React.FC = () => {
  const { modelId, tableId } = useParams<{
    modelId: string;
    tableId: string;
  }>();
  const {
    targetTableDefinition: targetTableDetails,
    targetTableDefinitionsPackage: modelDetails,
  } = useModelTableDetails(modelId, tableId) ?? {};
  const history = useHistory();
  const [showCreatePanel, setShowCreatePanel] = useState<boolean>(false);
  const handleCreateClick = () => {
    setShowCreatePanel(true);
  };
  const [, setTemplateData] = useCherreState(extractionTemplateState);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ExtractionTemplateFormData>({
    resolver: zodResolver(extractionTemplateSchema),
    defaultValues: {
      templateName: '',
      erpSystem: '',
      fileType: '',
    },
  });
  const handleSubmitClick = () => {
    handleSubmit((data) =>
      setTemplateData((prev) => ({
        ...prev,
        ...data,
      }))
    )();
    history.push(
      generatePath(TableExtractionTemplateUploadRoute.routeDescriptor.path, {
        modelId,
        tableId,
      })
    );
  };

  return (
    <PageContainer id='TableDetailsPage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='TableDetailsBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs
            modelName={modelDetails?.displayName ?? modelDetails?.name}
            tableName={
              targetTableDetails?.displayName ?? targetTableDetails?.name
            }
          />
        </Panel>
        <Panel id='TableDetailsHeader' behavior='fallback'>
          <Header
            title={
              targetTableDetails?.displayName ?? targetTableDetails?.name ?? ''
            }
          />
        </Panel>

        <Panel id='TableDetailsTemplates' behavior='fallback'>
          <TemplatesSectionWrapper handleCreateClick={handleCreateClick} />
        </Panel>
        {showCreatePanel && (
          <Panel
            id='ExtractionTemplateCreateDetailsContent'
            behavior='fallback'
          >
            <Paper
              sx={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                  <Controller
                    name='templateName'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Extraction Template Name'
                        required
                        fullWidth
                        size='small'
                        error={!!errors.templateName}
                        helperText={errors.templateName?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <Controller
                    name='erpSystem'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='ERP System'
                        size='small'
                        fullWidth
                        error={!!errors.erpSystem}
                        helperText={errors.erpSystem?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <Controller
                    name='fileType'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='File Type'
                        size='small'
                        fullWidth
                        error={!!errors.fileType}
                        helperText={errors.fileType?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box
                display='flex'
                flexDirection='row'
                gap='20px'
                justifyContent={'end'}
                sx={{ mt: 2 }}
              >
                <Button
                  onClick={() =>
                    history.push(
                      generatePath(
                        ModelTableDetailsRoute.routeDescriptor.path,
                        { modelId, tableId }
                      )
                    )
                  }
                >
                  Back
                </Button>

                <Button variant='contained' onClick={handleSubmitClick}>
                  Next
                </Button>
              </Box>
            </Paper>
          </Panel>
        )}
      </Box>
    </PageContainer>
  );
};

ExtractionTemplateDetails.displayName = 'ExtractionTemplateDetails';
export default ExtractionTemplateDetails;
