/**
 * @generated SignedSource<<25b2b8f68ee0c108535eddd035312f18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useModelTableDetailsQuery$variables = {
  modelId?: string | null;
  targetTableDefinitionId?: string | null;
};
export type useModelTableDetailsQuery$data = {
  readonly targetTableDefinition: {
    readonly columns: ReadonlyArray<{
      readonly description: string | null;
      readonly displayName: string | null;
      readonly dsp: {
        readonly mappingFields: ReadonlyArray<{
          readonly displayName: string;
          readonly mappingName: string;
          readonly required: boolean;
          readonly targetName: string;
          readonly tsa: {
            readonly enabled: boolean;
            readonly required: boolean;
          } | null;
        }> | null;
        readonly required: boolean;
        readonly tsa: {
          readonly enabled: boolean;
          readonly required: boolean;
        } | null;
      } | null;
      readonly name: string;
      readonly type: string;
    }>;
    readonly description: string | null;
    readonly displayName: string;
    readonly dsp: {
      readonly orgSlug: string | null;
      readonly submissionType: string | null;
    } | null;
    readonly name: string;
    readonly owner: {
      readonly name: string;
    };
    readonly uid: string;
  };
  readonly targetTableDefinitionsPackage: {
    readonly displayName: string;
    readonly name: string;
    readonly uid: string;
  };
};
export type useModelTableDetailsQuery = {
  response: useModelTableDetailsQuery$data;
  variables: useModelTableDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "modelId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "targetTableDefinitionId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "tsaFields",
  "kind": "LinkedField",
  "name": "tsa",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "modelId"
      }
    ],
    "concreteType": "TargetTableDefinitionsPackage",
    "kind": "LinkedField",
    "name": "targetTableDefinitionsPackage",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "targetTableDefinitionId"
      }
    ],
    "concreteType": "TargetTableDefinition",
    "kind": "LinkedField",
    "name": "targetTableDefinition",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Owner",
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "targetTableDspDefinition",
        "kind": "LinkedField",
        "name": "dsp",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submissionType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orgSlug",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Column",
        "kind": "LinkedField",
        "name": "columns",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DspColumn",
            "kind": "LinkedField",
            "name": "dsp",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "mappingFields",
                "kind": "LinkedField",
                "name": "mappingFields",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mappingName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetName",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useModelTableDetailsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useModelTableDetailsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "3e2499fc8ab0f3128b1b05277917b72c",
    "id": null,
    "metadata": {},
    "name": "useModelTableDetailsQuery",
    "operationKind": "query",
    "text": "query useModelTableDetailsQuery(\n  $modelId: String\n  $targetTableDefinitionId: String\n) {\n  targetTableDefinitionsPackage(uid: $modelId) {\n    uid\n    name\n    displayName\n  }\n  targetTableDefinition(uid: $targetTableDefinitionId) {\n    uid\n    name\n    displayName\n    description\n    owner {\n      name\n    }\n    dsp {\n      submissionType\n      orgSlug\n    }\n    columns {\n      name\n      displayName\n      type\n      description\n      dsp {\n        required\n        mappingFields {\n          mappingName\n          targetName\n          displayName\n          required\n          tsa {\n            required\n            enabled\n          }\n        }\n        tsa {\n          required\n          enabled\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "baf688d23cf28eaa890690679af71e84";

export default node;
