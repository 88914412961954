import {
  PageContainer,
  Panel,
  Box,
  Tabs,
  CopyIcon,
  Button,
  DataConnectionIcon,
  DataSettingsIcon,
  SettingsIcon,
  Typography,
  MuiAutocomplete as Autocomplete,
  TextField,
  Paper,
  Divider,
} from '@cherre-frontend/ui';
import React, { useState, useEffect } from 'react';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import Header from '../../components/ModelsLibraryHeader';
import { useModelTableDetails } from '../../services/dom/useModelTableDetails';
import SchemaTable from '../../components/SchemaTable';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import DspMappingTable from '../../components/DspMappingTable';
import DspConfigurationTable from '../../components/DspConfigurationTable';
import { useTargetTableForms } from '../../hooks/useTargetTableForms';
import { TabValue, TSA_VALUES } from '../../schema';
import { Controller } from 'react-hook-form';
import { TableExtractionTemplateCreateRoute } from '../../routes';
import TemplatesSectionWrapper from '../../components/TemplatesSection';

const TargetTableDetails: React.FC = () => {
  const history = useHistory();
  const { modelId, tableId } = useParams<{
    modelId: string;
    tableId: string;
  }>();
  const {
    targetTableDefinition: targetTableDetails,
    targetTableDefinitionsPackage: modelDetails,
  } = useModelTableDetails(modelId, tableId) ?? {};
  const [tabValue, setTabValue] = useState(TabValue.TableSchema);

  // Initialize forms using our custom hook
  const {
    tableForm,
    mappingForm,
    tsaForm,
    values,
    setTableData,
    setMappingTableData,
    setTsaTableData,
  } = useTargetTableForms();

  const { tableData, fieldNameOptions, mappingFieldsData, tsaFieldsData } =
    values;

  // Load data from API into forms when available
  useEffect(() => {
    console.log('inside useEffect');
    if (targetTableDetails) {
      // Format columns data for the form
      const formattedColumns =
        targetTableDetails.columns?.map((column) => ({
          ...column,
          dsp: column.dsp
            ? {
                ...column.dsp,
                tsa: column.dsp.tsa ? { ...column.dsp.tsa } : null,
                mappingFields:
                  column.dsp.mappingFields?.map((field) => ({
                    ...field,
                    tsa: field.tsa ? { ...field.tsa } : null,
                  })) ?? [],
              }
            : null,
        })) || [];

      // Extract mapping data from columns
      const mappingFields =
        targetTableDetails.columns?.flatMap((column) => {
          if (!column.dsp?.mappingFields?.length) {
            return [];
          }

          return column.dsp.mappingFields.map((mappingField) => ({
            fieldName: column.name,
            referenceColumn: mappingField.targetName || '',
            lookupTable: mappingField.displayName || '',
            lookupColumn: '',
            //TODO: @gaspardetienne97 get the description from the mappingField query when it is available
            description: mappingField.displayName || '',
          }));
        }) || [];
      // Extract TSA data from columns
      const tsaFields =
        targetTableDetails.columns?.map((column) => {
          const tsaValue = column.dsp?.tsa
            ? column.dsp.tsa.required
              ? TSA_VALUES.REQUIRED
              : column.dsp.tsa.enabled
              ? TSA_VALUES.ENABLED
              : TSA_VALUES.DISABLED
            : TSA_VALUES.DISABLED;

          return {
            fieldName: column.name,
            tsa: tsaValue,
          };
        }) || [];
      // Reset forms with data from API
      tableForm.reset({
        tableName:
          targetTableDetails.displayName || targetTableDetails.name || '',
        description: targetTableDetails.description || '',
        dsp: !!targetTableDetails.dsp,
        submissionType: targetTableDetails.dsp?.submissionType || undefined,
        orgSlug: targetTableDetails.dsp?.orgSlug || undefined,
        tableData: formattedColumns,
      });

      // Reset mapping form
      mappingForm.reset({
        fields:
          mappingFields.length > 0
            ? mappingFields
            : [
                {
                  fieldName: '',
                  referenceColumn: '',
                  lookupTable: '',
                  lookupColumn: '',
                  description: '',
                },
              ],
      });

      // Reset TSA form
      tsaForm.reset({
        fields:
          tsaFields.length > 0
            ? tsaFields
            : [
                {
                  fieldName: '',
                  tsa: TSA_VALUES.DISABLED,
                },
              ],
      });
    }
    //TODO: @gaspardetienne97 fix the useEffect dependencies
  }, [targetTableDetails]);

  const handleDuplicateTableClick = (_event: React.MouseEvent) => {
    //TODO: @gaspardetienne97 implement handleDuplicateTableClick
    throw new Error('Function not implemented.');
  };

  const handleCreateClick = () => {
    history.push(
      generatePath(TableExtractionTemplateCreateRoute.routeDescriptor.path, {
        modelId,
        tableId,
      })
    );
  };

  return (
    <PageContainer id='TableDetailsPage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='TableDetailsBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs
            modelName={modelDetails?.displayName ?? modelDetails?.name}
            tableName={
              targetTableDetails?.displayName ?? targetTableDetails?.name
            }
          />
        </Panel>
        <Panel id='TableDetailsHeader' behavior='fallback'>
          <Header
            title={
              targetTableDetails?.displayName ?? targetTableDetails?.name ?? ''
            }
          />
        </Panel>
        <Panel id='TableDetailsOrganization' behavior='fallback'>
          <Paper
            sx={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Box display='flex' flexDirection='column' gap='8px'>
              <Typography variant='h6'>Organizations</Typography>
              <Typography variant='body1'>
                Search and select which organizations this target table should
                be assigned to in order for them to view the below Target Table
                in their library.
              </Typography>
              <Box flexGrow={1} />
              {/* //TODO:@gaspardetienne97 ask if we should get the org name from the slug and display it here */}
              <TextField
                defaultValue={targetTableDetails?.dsp?.orgSlug}
                color='primary'
                placeholder='Organization'
                size='small'
                disabled
              />
            </Box>
          </Paper>
        </Panel>
        <Panel id='TableDetailsTemplates' behavior='fallback'>
          <TemplatesSectionWrapper handleCreateClick={handleCreateClick} />
        </Panel>
        <Panel id='TargetTableDetailsContent' behavior='fallback'>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '20px',
              padding: '20px',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
              aria-label='Target Table tabs '
              showBorder={false}
              muiTabsProps={{
                TabIndicatorProps: {
                  sx: {
                    top: 0,
                  },
                },
              }}
              tabs={[
                {
                  label: 'Table Schema',
                  icon: <DataConnectionIcon />,
                  iconPosition: 'start',
                  value: TabValue.TableSchema,
                },
                {
                  label: 'Mapping Configurations',
                  icon: <DataSettingsIcon />,
                  iconPosition: 'start',
                  value: TabValue.MappingConfig,
                },
                {
                  label: 'Product Configurations',
                  icon: <SettingsIcon />,
                  iconPosition: 'start',
                  value: TabValue.ProductConfig,
                },
              ]}
              tabPanels={[
                {
                  value: TabValue.TableSchema,
                  panel: (
                    <SchemaTable
                      control={tableForm.control}
                      tableCreated
                      targetTableState={tableData}
                      setTargetTableState={setTableData}
                    />
                  ),
                },
                //TODO: @gaspardetienne97 implement the Mapping Configurations tab to actually display the mapping configurations this is just a placeholder to show the ui
                {
                  value: TabValue.MappingConfig,
                  panel: (
                    <Box display='flex' flexDirection='column' gap='20px'>
                      <Box display='flex' flexDirection='column' gap='8px'>
                        <Typography variant='h6'>
                          Configure mapped fields below
                        </Typography>
                        <Typography variant='body1'>
                          From the table schema created, are there any fields
                          that map to another? If so, add those fields below.
                        </Typography>
                      </Box>
                      <DspMappingTable
                        control={mappingForm.control}
                        tableCreated
                        targetTableState={mappingFieldsData}
                        setTargetTableState={setMappingTableData}
                        fieldNameOptions={fieldNameOptions}
                      />
                    </Box>
                  ),
                },
                //TODO: @gaspardetienne97 implement the Product Configurations tab to actually display the product configurations this is just a placeholder to show the ui
                {
                  value: TabValue.ProductConfig,
                  panel: (
                    <Box display='flex' flexDirection='column' gap='20px'>
                      <Box display='flex' flexDirection='column' gap='8px'>
                        <Typography variant='h6'>
                          Data Submission Portal - Not Required Fields
                        </Typography>
                        <Typography variant='body1'>
                          From the table schema created, are there any fields
                          that are not required for data submissions?
                        </Typography>
                        <Controller
                          render={({ ...props }) => (
                            <Autocomplete
                              options={fieldNameOptions}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color='primary'
                                  placeholder='Select'
                                  size='small'
                                />
                              )}
                              onChange={(e, data) => props.field.onChange(data)}
                              fullWidth
                              {...props}
                            />
                          )}
                          name={`requiredFields`}
                          control={tsaForm.control}
                        />
                      </Box>
                      <Divider />
                      <Typography variant='h6'>
                        Data Submission Portal - Topside Adjustments
                      </Typography>
                      <DspConfigurationTable
                        control={tsaForm.control}
                        tableCreated
                        targetTableState={tsaFieldsData}
                        setTargetTableState={setTsaTableData}
                        fieldNameOptions={fieldNameOptions}
                      />
                    </Box>
                  ),
                },
              ]}
            />
            <Button
              startIcon={<CopyIcon />}
              onClick={handleDuplicateTableClick}
            >
              Duplicate Target Table
            </Button>
          </Paper>
        </Panel>
      </Box>
    </PageContainer>
  );
};

TargetTableDetails.displayName = 'TargetTableDetails';
export default TargetTableDetails;
