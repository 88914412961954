import React from 'react';
import {
  Avatar,
  Typography,
  IconButton,
  Badge,
  Button,
  DataGridIcon,
  CityIcon,
  CopyIcon,
  ArrowForwardIcon,
  Stack,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@cherre-frontend/ui';
import { useHistory } from 'react-router-dom';

interface LibraryCardProps {
  cardTitle: string;
  dsp?: boolean;
  viewLink: string;
  viewText?: string;
  onDataGridClick?: () => void;
  onCityClick?: () => void;
  onCopyClick?: () => void;
}

export const LibraryCard: React.FC<LibraryCardProps> = ({
  cardTitle,
  dsp,
  viewLink,
  viewText = 'View',
  onDataGridClick,
  onCityClick,
  onCopyClick,
}) => {
  const history = useHistory();
  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8px',
          paddingX: '12px',
          paddingTop: '8px',
        }}
      >
        <Avatar
          alt={cardTitle}
          //TODO:@gaspardetienne97 ask @alexguanga about target table image
          src={'/assets/cherre-logo-red.png'}
          imgProps={{
            sx: {
              height: '30px',
              width: '30px',
              objectFit: 'contain',
            },
          }}
          sx={{ height: '30px', width: '30px' }}
        />
        <Typography variant='h5' padding='4px' component='div' noWrap>
          {cardTitle}
        </Typography>
        {dsp && (
          <Chip label='Data Submission Portal' size='small' color={'info'} />
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between', paddingX: '12px' }}>
        <Stack direction='row' spacing={3} useFlexGap>
          {onDataGridClick && (
            <IconButton
              data-testid='DataGridIconButton'
              sx={{ height: '20px', width: '20px' }}
              color='success'
              onClick={onDataGridClick}
            >
              <Badge badgeContent={4} color='success'>
                <DataGridIcon />
              </Badge>
            </IconButton>
          )}
          {onCityClick && (
            <IconButton
              data-testid='CityIconButton'
              sx={{ height: '20px', width: '20px' }}
              color='primary'
              onClick={onCityClick}
            >
              <CityIcon />
            </IconButton>
          )}
          {onCopyClick && (
            <IconButton
              data-testid='CopyIconButton'
              sx={{ height: '20px', width: '20px' }}
              color='primary'
              onClick={onCopyClick}
            >
              <CopyIcon />
            </IconButton>
          )}
        </Stack>
        <Button
          size='small'
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            history.push(viewLink);
          }}
        >
          {viewText}
        </Button>
      </CardActions>
    </Card>
  );
};

LibraryCard.displayName = 'LibraryCard';
export default LibraryCard;
