import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  useUnifiedIngestReadTemplatesQuery,
  unified_ingest_transform_templates_read_params,
} from './__generated__/useUnifiedIngestReadTemplatesQuery.graphql';

export const $unifiedIngestReadTransformTemplates = graphQLSelector({
  query: graphql`
    query useUnifiedIngestReadTemplatesQuery(
      $params: unified_ingest_transform_templates_read_params!
    ) {
      unified_ingest_transform_templates_read(params: $params) {
        owner_name
        template_data
      }
    }
  ` as GraphQLReturn<useUnifiedIngestReadTemplatesQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_transform_templates_read;
  },
});

export const useUnifiedIngestReadTransformTemplates = (
  params: unified_ingest_transform_templates_read_params
) => {
  return useCherreValue(
    $unifiedIngestReadTransformTemplates({
      params,
    })
  );
};
