import React, { useCallback, useMemo } from 'react';
import {
  IngestionFlow,
  IngestionFlowActiveStepKey,
  IngestionFlowState,
} from 'src/products/ingestion/components/IngestionFlow/IngestionFlow';
import { extractionTemplateState } from '.';
import {
  searchParamsSelector,
  useCherreState,
  RelayEnvironmentScope,
} from '@cherre-frontend/data-fetching';
import { useModelTableDetails } from '../../services/dom/useModelTableDetails';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  withDefault,
  writableArray,
  writableObject,
  nullable,
  object,
  string,
  array,
  number,
  bool,
  dict,
} from '@recoiljs/refine';
import { TableExtractionTemplateCreateRoute } from '../../routes';
import { useDspRelayEnvironment } from 'src/products/connector/pages/connector-details/components/DataValidationV2/hooks/useDspRelayEnvironment';
import { useModelTableDetailsQuery$data } from '../../services/dom/__generated__/useModelTableDetailsQuery.graphql';
import { useUnifiedIngestWriteTemplates } from '../../services/dsp/useUnifiedIngestWriteTemplates';
import { useSelector } from 'react-redux';
import { snakeCase } from 'lodash';
import { useAppContext } from '@cherre-frontend/core';

export const ingestFlowState = searchParamsSelector({
  key: 'ingest-upload-process-template-flow-state',
  refine: withDefault(
    object({
      signedUrl: nullable(string()),
      fileId: nullable(string()),
      selectedSheet: nullable(string()),
      activeStepKey: withDefault(string(), 'upload'),
      selectedHeaderRows: nullable(array(number())),
      toolSteps: nullable(
        writableArray(
          writableObject({
            selected_tool_name: string(),
            tool_input: writableArray(dict(string())),
          })
        )
      ),
      validationResults: nullable(
        object({
          validation_id: string(),
          checks_summary: writableArray(
            writableObject({
              failed: bool(),
              failed_rows: writableArray(number()),
              field: string(),
              rule: string(),
            })
          ),
        })
      ),
    }),
    {
      signedUrl: null,
      fileId: null,
      selectedSheet: null,
      activeStepKey: 'upload',
      selectedHeaderRows: null,
      validationResults: null,
      toolSteps: null,
    }
  ),
  routes: ['/**'],
});

interface UploadExtractionTemplateProps {
  modelId: string;
  tableId: string;
  targetTableDetails?: useModelTableDetailsQuery$data['targetTableDefinition'];
}
const UploadExtractionTemplate: React.FC<UploadExtractionTemplateProps> = ({
  modelId,
  tableId,
  targetTableDetails,
}) => {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();
  const ctx = useAppContext();
  const [templateData] = useCherreState(extractionTemplateState);

  const { organizationSlug, id, domOwner } = useSelector(
    (state) => state.user.profile.value
  );
  const writeTemplate = useUnifiedIngestWriteTemplates();

  const pushOrGoBack = useCallback(() => {
    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      push(
        generatePath(TableExtractionTemplateCreateRoute.routeDescriptor.path, {
          modelId,
          tableId,
        })
      );
    }
  }, [location.state?.shouldGoBack]);

  const [flowState, setFlowState] = useCherreState(ingestFlowState);

  const targetSchema =
    targetTableDetails?.columns.map((column) => ({
      label: column.displayName ?? '',
      name: column.name,
      mandatory: column.dsp?.required ?? true,
      dataType: column.type,
    })) ?? [];

  const state = useMemo(
    () => ({
      fileId: flowState?.fileId ?? undefined,
      signedUrl: flowState?.signedUrl ?? undefined,
      selectedSheet: flowState?.selectedSheet ?? undefined,
      activeStepKey: (flowState?.activeStepKey ??
        'upload') as IngestionFlowActiveStepKey,
      selectedHeaderRows: flowState?.selectedHeaderRows ?? undefined,
      validationResults: flowState?.validationResults ?? undefined,
      toolSteps: flowState?.toolSteps ?? undefined,
    }),
    [flowState]
  );
  const setState = useCallback(
    (newState: React.SetStateAction<IngestionFlowState>) => {
      setFlowState((prev) => ({
        ...prev,
        ...(typeof newState === 'function'
          ? newState({
              fileId: prev?.fileId ?? undefined,
              signedUrl: prev?.signedUrl ?? undefined,
              selectedSheet: prev?.selectedSheet ?? undefined,
              activeStepKey: (prev?.activeStepKey ??
                'upload') as IngestionFlowActiveStepKey,
              selectedHeaderRows: prev?.selectedHeaderRows ?? undefined,
              validationResults: prev?.validationResults ?? undefined,
              toolSteps: prev?.toolSteps ?? undefined,
            })
          : newState),
      }));
    },
    []
  );
  const onComplete = useCallback(async () => {
    //TODO: set the template to the file reference source
    try {
      const steps =
        state?.toolSteps?.reduce((acc, step, index) => {
          const { selected_tool_name, tool_input } = step;
          return {
            ...acc,
            [`step${index + 1}`]: {
              function_name: selected_tool_name,
              function_input: tool_input,
            },
          };
        }, {}) ?? {};
      await writeTemplate({
        params: {
          owner_name: domOwner ?? '',
          template_id: snakeCase(
            templateData?.templateName
          ).toLocaleLowerCase(),
          user_id: id?.toString() ?? '',
          org_slug: organizationSlug ?? '',
          template_display_name: templateData?.templateName ?? '',
          source_system: snakeCase(templateData?.erpSystem).toLocaleLowerCase(),
          target_table: targetTableDetails?.name,
          file_type: snakeCase(templateData?.fileType).toLocaleLowerCase(),
          is_production: true,
          steps,
        },
      });

      ctx.showSnackbar({
        message: 'Successfully created Template',
        type: 'success',
      });
    } catch (error) {
      ctx.showSnackbar({
        message: 'Error creating Template',
        type: 'error',
      });
    }
  }, [targetTableDetails?.name, templateData, domOwner, id, organizationSlug]);

  const dspEnvironment = useDspRelayEnvironment();
  if (!dspEnvironment) {
    return null;
  }
  return (
    <RelayEnvironmentScope environment='view_admin'>
      <IngestionFlow
        config={{
          templateId: '',
          datasetId: '',
          ownerName: targetTableDetails?.owner.name ?? '',
          datasetName: templateData?.templateName ?? '',
          performValidation: false,
          showTools: true,
          targetSchema,
        }}
        ingestionConfig={undefined}
        onComplete={onComplete}
        onCancel={pushOrGoBack}
        state={state}
        setState={setState}
      />
    </RelayEnvironmentScope>
  );
};
const UploadExtractionTemplateWrapper = () => {
  const { modelId, tableId } = useParams<{
    modelId: string;
    tableId: string;
  }>();
  const { targetTableDefinition: targetTableDetails } =
    useModelTableDetails(modelId, tableId) ?? {};
  return (
    <RelayEnvironmentScope environment='view_admin'>
      <UploadExtractionTemplate
        modelId={modelId}
        tableId={tableId}
        targetTableDetails={targetTableDetails}
      />
    </RelayEnvironmentScope>
  );
};
UploadExtractionTemplateWrapper.displayName = 'UploadExtractionTemplateWrapper';
export default UploadExtractionTemplateWrapper;
